exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contato-enviado-js": () => import("./../../../src/pages/contato-enviado.js" /* webpackChunkName: "component---src-pages-contato-enviado-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-produtos-index-js": () => import("./../../../src/pages/produtos/index.js" /* webpackChunkName: "component---src-pages-produtos-index-js" */),
  "component---src-pages-sobre-nos-js": () => import("./../../../src/pages/sobre-nos.js" /* webpackChunkName: "component---src-pages-sobre-nos-js" */),
  "component---src-templates-composition-template-js": () => import("./../../../src/templates/composition-template.js" /* webpackChunkName: "component---src-templates-composition-template-js" */),
  "component---src-templates-product-template-js": () => import("./../../../src/templates/product-template.js" /* webpackChunkName: "component---src-templates-product-template-js" */)
}

